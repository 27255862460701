<template>
  <div>
    <en-table-layout :tableData="tableData.data" :loading="loading" :tips="true">
      <!-- 搜索条件 -->
      <div slot="tips" class="TitleBox">
        <div class="conditions">
          <el-button
            @click="openDialog('new')"
            type="primary"
            class="btn-default"
            style="margin-right:40px;"
            size="mini"
          >新建
          </el-button>
        </div>
        <div style="width:400px;display:flex;">
          <div class="conditions" style="margin-right:10px;">
            <el-select v-model="params.addr_type" placeholder="请选择地址类型" clearable size="mini">
              <el-option
                v-for="item in addrTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="conditions">
            <el-input v-model="params.addr_detail" placeholder="请输入地址名称" class="ipt-default" size="mini"
                      style="margin-right:10px;"></el-input>
            <el-button @click="searchAddressList" type="primary" class="btn-default" size="mini">搜索</el-button>
          </div>
        </div>
      </div>
      <template slot="table-columns">
        <!-- 编号 -->
        <el-table-column label="编号">
          <template slot-scope="scope">
            <div>T{{ scope.row.id }}</div>
          </template>
        </el-table-column>
        <!-- 地址 -->
        <el-table-column label="地址">
          <template slot-scope="scope">
            <div>
              {{
                [scope.row.province, scope.row.city, scope.row.country, scope.row.addr_detail].filter(s => !!s).join('')
              }}
            </div>
          </template>
        </el-table-column>
        <!--地址类型-->
        <el-table-column label="地址类型">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.addr_type === 1 ? '集合地' : scope.row.addr_type === 2 ? '活动地点' : scope.row.addr_type === 3 ? '店铺地址' : ''
              }}
            </div>
          </template>
        </el-table-column>
        <!--创建时间-->
        <el-table-column prop="create_date" :formatter="MixinUnixToDate" label="创建时间"/>
        <!--操作-->
        <el-table-column label="操作" width="130">
          <template slot-scope="scope">
            <div class="buttonBox">
              <el-button
                @click="openDialog('edit',scope.row)"
                type="text"
                size="mini"
                style="margin-bottom:5px;"
              >编辑
              </el-button>
              <el-button
                @click="openDialog('del',scope.row)"
                type="text"
                size="mini"
                style="margin-bottom:5px;margin-left:0;color:#fe5558;"
              >删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </template>
      <el-pagination
        v-if="tableData.data"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>
    <el-dialog :title="dialogTitle" :visible.sync="centerDialogVisible" width="40%" center>
      <template v-if="centerDialogVisible">
        <div class="dialogContent" v-if="dialogTitle === '删除地址'">{{ dialogContent }}</div>
        <div class="dialogBox" v-if="dialogTitle !== '删除地址'">
          <div class="left">
            <div>地址类型选择：</div>
            <div>地址：</div>
            <div>详细地址：</div>
          </div>
          <div class="right">
            <div>
              <el-radio v-model="addParams.addr_type" :label="1">集合地</el-radio>
              <el-radio v-model="addParams.addr_type" :label="2">活动地点</el-radio>
              <el-radio v-model="addParams.addr_type" :label="3">店铺地址</el-radio>
            </div>
            <div>
              <address-selector :value="address" @change="handleChangeArea"></address-selector>
            </div>
            <div>
              <el-input
                v-model="addParams.addr_detail"
                class="ipt-default"
                placeholder="请录入详细地址便于地图定位 例：中关村大街18号B座中关村互联网教育创新中心1901室"
              ></el-input>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="ok">确 定</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as API_activityAddress from "@/api/activityAddress";
import AddressSelector from "@/components-new/address-selector/address-selector";

export default {
  name: "activityList",
  components: {
    AddressSelector
  },
  data() {
    return {
      //  列表loading状态
      loading: false,

      // 列表参数
      params: {
        page_no: 1,
        page_size: 10,
        addr_type: '',
        addr_detail: '',
      },

      // 地址类型选择
      addrTypeOptions: [
        {
          value: 1,
          label: '集合地'
        },
        {
          value: 2,
          label: '活动地点'
        },
        {
          value: 3,
          label: '店铺地址'
        },
      ],

      //  列表数据
      tableData: [],

      // 弹框显示内容
      dialogTitle: "",
      centerDialogVisible: false,

      // 新建和编辑地址
      addParams: {
        addr_type: "",
        province_id: "",
        city_id: "",
        country_id: "",
        addr_detail: "",
      },

      // 编辑回显地址区域
      address: [],
    };
  },
  mounted() {
    // 获取地址列表
    this.POST_activeAddressShopPage();
  },
  activated() {
    // 获取地址列表
    this.POST_activeAddressShopPage();
  },
  methods: {
    refresh() {
      // TODO 只有页面中实现了 refresh 方法之后，右上角才会有刷新按钮
      console.log('refresh');
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.POST_activeAddressShopPage();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.POST_activeAddressShopPage();
    },

    /** 查询地址列表 */
    searchAddressList() {
      this.params.page_no = 1
      this.POST_activeAddressShopPage()
    },

    /** 获取地址列表 */
    POST_activeAddressShopPage() {
      API_activityAddress.activeAddressShopPage(this.params).then((res) => {
        this.tableData = res;
      });
    },

    /** 新增地址 */
    POST_activeAddressAdd() {
      API_activityAddress.activeAddressAdd(this.addParams).then((res) => {
        this.addParams = {
          addr_type: "",
          province_id: "",
          city_id: "",
          country_id: "",
          addr_detail: ""
        };
        this.$message.success("新增成功！");
        this.POST_activeAddressShopPage();
      });
    },

    /** 编辑地址 */
    POST_activeAddressEdit() {
      API_activityAddress.activeAddressEdit(this.addParams).then((res) => {
        this.addParams = {
          addr_type: "",
          province_id: "",
          city_id: "",
          country_id: "",
          addr_detail: ""
        };
        this.$message.success("修改成功！");
        this.POST_activeAddressShopPage();
        console.log(res);
      });
    },

    /** 删除地址 */
    DELETE_activeAddressEdit() {
      API_activityAddress.activeAddressDel(this.addParams.id).then((res) => {
        this.addParams = {
          addr_type: "",
          province_id: "",
          city_id: "",
          country_id: "",
          addr_detail: ""
        };
        this.$message.success("删除成功！");
        this.POST_activeAddressShopPage();
        // console.log(res);
      });
    },

    /** 区域选择 */
    handleChangeArea(val) {
      const keys = ['province_id', 'city_id', 'country_id'];
      keys.forEach((key, index) => {
        this.addParams[key] = val[index] ? val[index].id : 0;
      });
    },

    /** 打开弹窗 */
    openDialog(type, row) {
      if (row) {
        row = JSON.parse(JSON.stringify(row));
      } else {
        row = {};
      }
      if (type === "new") {
        this.dialogTitle = "新建地址";
        this.address = [];
        this.addParams = row;
      } else if (type === "edit") {
        this.dialogTitle = "编辑地址";
        this.addParams = row;
        this.address = [row.province_id, row.city_id, row.country_id];
      } else if (type === "del") {
        this.dialogTitle = "删除地址";
        this.dialogContent = "删除地址将无法找回，确认删除吗？";
        this.addParams = row;
      }
      this.centerDialogVisible = true;
    },

    /** 弹窗点击确定 */
    ok() {
      if (this.dialogTitle === "新建地址") {
        if (!this.addParams.addr_type) {
          this.$message.error("请选择地址类型！");
          return false;
        }
        if (!this.addParams.province_id) {
          this.$message.error("请选择地址区域！");
          return false;
        }
        if (!this.addParams.addr_detail) {
          this.$message.error("请选择详细地址！");
          return false;
        }
        // console.log(this.addParams);
        this.POST_activeAddressAdd();
      } else if (this.dialogTitle === "编辑地址") {
        if (!this.addParams.addr_type) {
          this.$message.error("请选择地址类型！");
          return false;
        }
        if (!this.addParams.province_id) {
          this.$message.error("请选择地址区域！");
          return false;
        }
        if (!this.addParams.addr_detail) {
          this.$message.error("请选择详细地址！");
          return false;
        }
        // console.log(this.addParams);
        this.POST_activeAddressEdit();
      } else if (this.dialogTitle === "删除地址") {
        this.DELETE_activeAddressEdit();
      }
      this.centerDialogVisible = false;
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
/deep/ .el-table td:not(.is-left) {
  text-align: center;
}

.TitleBox {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .conditions {
    display: flex;
    align-items: center;

    .choose-machine {
      width: 60%;
    }
  }
}

.buttonBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialogContent {
  font-size: 16px;
  font-weight: 500;
  color: rgba(102, 102, 102, 1);
  text-align: center;
}

.dialogBox {
  display: flex;

  .left {
    div {
      line-height: 40px;
      font-size: 16px;
      text-align: right;
    }
  }

  .right {
    flex: 1;

    div {
      height: 40px;
      line-height: 40px;
    }
  }
}

/deep/ .toolbar {
  padding: 0 !important;
}

.elTabBox {
  /deep/ .el-tabs__item {
    background: #fff;
  }

  /deep/ .is-active {
    background: #008080;
    color: #fff;
  }

  /deep/ #tab-first {
    border-radius: 4px 0 0 4px;
  }

  /deep/ #tab-second {
    border-radius: 0 4px 4px 0;
  }
}
</style>
